import { Component } from 'vue-property-decorator';
import { siteService, facilityService } from '@/main';
import Facility from '../../models/Facility';
import { BModal } from 'bootstrap-vue';
import FacilityGroup from '../../models/FacilityGroup';
import to from 'await-to-js';
import BasePage from '../../models/BasePage';
import { AxiosResponse } from 'axios';
import { required } from 'vuelidate/lib/validators';
import { IVuelidate, validationMixin } from 'vuelidate';

@Component({
    mixins: [validationMixin],
    validations: {
        site: {
            name: { required },
            location: {
                name: { required },
            },
        },
    },
})
export default class SiteComponent extends BasePage implements IVuelidate<any> {
    public facilities: Facility[] = null;
    public facilityGroups: FacilityGroup[] = null;
    public flatOptionList: Facility[] = null;
    public uploadImageTxt: string = '';
    public selected: number = null;
    public settings = {
        valueField: 'facilityId',
        labelField: 'name',
        options: this.facilities,
        openOnFocus: true,
        searchField: ['name'],
        placeholder: 'Selecteer een faciliteit',
        create: this.openAddFacilityPopup,
        optgroups: [],
        optgroupField: 'facilityGroupId',
    };

    public newVideo: {} = {
        videoType: 'YouTube',
    };

    public newFacilityModel: Facility = { name: '', isSystemFacility: false, group: { facilityGroupId: null } } as Facility;

    public $refs!: {
        uploadImage: any;
        newVideoModal: BModal;
        newFacilityModal: BModal;
    };

    public async created() {
        await this.initSite();

        await this.loadFacilities();
        await this.loadFacilityGroups();

        this.facilities.forEach((facility: Facility) => {
            facility.facilityGroupId = facility.group.facilityGroupId;
        });

        this.isLoading = false;
    }

    public getGroupedFacilities() {
        const groupedFacilities = [];
        this.facilityGroups.forEach((facilityGroup: FacilityGroup) => {
            groupedFacilities.push({
                group: facilityGroup.name,
                items: this.facilities.filter((facility: Facility) => {
                    return (
                        facility.group.facilityGroupId === facilityGroup.facilityGroupId &&
                        this.site.facilities.findIndex((fac) => fac.facilityId === facility.facilityId) === -1
                    );
                }),
            });
        });

        return groupedFacilities;
    }

    public getSiteFacilitiesPerGroup() {
        const facilitiesPerGroup = {};
        this.site.facilities.forEach((facility: Facility) => {
            const groupId = facility.facilityGroupId;
            if (!facilitiesPerGroup[groupId]) {
                facilitiesPerGroup[groupId] = {
                    group: new FacilityGroup(),
                    facilities: [],
                };
                facilitiesPerGroup[groupId].facilityGroupId = facility.facilityGroupId;
                facilitiesPerGroup[groupId].name = facility.facilityGroupName;
            }

            facilitiesPerGroup[groupId].facilities.push(facility);
        });
        return facilitiesPerGroup;
    }

    public async postSite() {
        if (!this.validateObject('site')) {
            return;
        }

        this.showPending('Park opslaan...');
        const [err, response] = await to(siteService.createOrUpdateSite(this.site));
        if (err) {
            return this.clearAndShowError('Mislukt om park op te slaan', err);
        }

        this.clearAndShowSuccess('Park succesvol opgeslagen');
        this.editMode = false;
    }

    public addFacility(facility: Facility) {
        this.site.facilities.push(facility);
    }

    public openAddFacilityPopup() {
        this.newFacilityModel = { name: 'Nieuwe facility', isSystemFacility: false, group: { facilityGroupId: null } } as Facility;
        this.$refs.newFacilityModal.show();
    }

    public async addNewVideo() {
        this.addVideoToSite(this.newVideo);
        this.newVideo = {};
        this.$refs.newVideoModal.hide();
    }

    public addVideoToSite(video) {
        if (!this.site.videos) {
            this.site.videos = [];
        }
        this.site.videos.push(video);
    }

    public async addNewFacility() {
        const [err, response] = await to<AxiosResponse<Facility>>(
            facilityService.createOrUpdateFacility(this.newFacilityModel, this.newFacilityModel.group.facilityGroupId),
        );
        if (err) {
            return this.showFailedResponse('Mislukt om faciliteit op te slaan', err);
        }

        const facility = new Facility(response.data);
        if (!this.newFacilityModel.facilityId) {
            this.facilities.push(facility);
            this.site.facilities.push(facility);
        } else {
            const foundFacility = this.site.facilities.find((facilityA) => facilityA.facilityId === this.newFacilityModel.facilityId);
            if (foundFacility) {
                foundFacility.name = this.newFacilityModel.name;
                foundFacility.description = this.newFacilityModel.description;
                foundFacility.group = this.newFacilityModel.group;
            }
        }

        this.$refs.newFacilityModal.hide();
    }

    public editFacility(facility: Facility) {
        this.newFacilityModel.facilityId = facility.facilityId;
        this.newFacilityModel.name = facility.name;
        this.newFacilityModel.description = facility.description;
        this.newFacilityModel.isSystemFacility = facility.isSystemFacility;
        this.newFacilityModel.group = facility.group;

        this.$refs.newFacilityModal.show();
    }

    public removeFacility(facility: Facility) {
        const position = this.site.facilities.indexOf(facility);
        this.site.facilities.splice(position, 1);
    }

    private async loadFacilities() {
        const [err, response] = await to(facilityService.getFacilities());
        if (err) {
            return this.showFailedResponse('Mislukt om faciliteiten op te halen', err);
        }
        this.facilities = response.data;
    }

    private async loadFacilityGroups() {
        const [err, response] = await to(facilityService.getFacilityGroups());
        if (err) {
            this.showFailedResponse('Mislukt om faciliteitgroepen op te halen', err);
            return (this.facilityGroups = []);
        }
        this.facilityGroups = response.data;
    }
}
